import React, { useState, useContext, useEffect } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { stripePayment, digitollPost, createRecord, formatAmount } from "../Redux/Action/Action";
import { successToast, warningToast, errorToast } from './toastify';
import AppContext from '../appContext';
import { useHistory, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Popup from 'reactjs-popup';
import PopupDialog from './mypopup';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};


const PaymentForm = (props) => {

  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const { exchangeRates } = useContext(AppContext);
  const stripe = useStripe();
  // const history = useHistory();
  const navigate = useNavigate();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState(false);
  const [cardExpiry, setCardExpiry] = useState(false);
  const [cardCvc, setCardCvc] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // useEffect(() => {

  // }, [])

  (async () => {
    const url = new URL(window.location);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    // console.log("clientSecret >>>>>>", clientSecret);
    if (clientSecret) {
      const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);
      {/*  AA - Feb 20 2024 - removed GiroPay as per Nurettin on Feb 19th meeting
           see removed-code.bak # removed-1
      */}
    }
  })();


  const handleClearParams = () => {
    navigate('/order');
  };

  const completeStripePaymentOnly = (event,tokerror,token,savePartnersSaleRes) => {
    if (!(event && event.id)) {
      console.log("Stripe >>>>>>");
      if (!tokerror) {
        stripePayment({
          amount: props.allInfo.amount,
          currency: props.allInfo.currency.toLowerCase(),
          token: token.id,
          email: props.loginUser.email,
          customer_name: props.loginUser.first_name ? (props.loginUser.first_name + ' ' + props.loginUser.last_name) : props.loginUser.email,
          stripeReturnUrl: "https://www.auto-vignette.com/order",
          user_id: props.loginUser._id,
          address: {
            line1: props.loginUser.address || "",
            postal_code: props.loginUser.postcode || "",
            city: props.loginUser.city || "",
            state: "",
            country: props.loginUser.country || ""
          }
        }).then(async (configRes) => {

          if (configRes['status']) {
            console.log("configRes >>>>>", configRes);
            if (configRes['data'].status === "succeeded") {
              token['stripe_order'] = configRes['data'];
              successToast("Payment successful for Auto-vignette");
              console.log("PaymentForm invoking callback1 with savePartnersSaleRes",savePartnersSaleRes);
              props.callback(token, "Stripe", savePartnersSaleRes);
            } 
            else if (configRes['data'].status === "requires_action") {
              const { error: actionError, paymentIntent: updatedPaymentIntent } = await stripe.confirmCardPayment(configRes['data'].client_secret);
              console.log("actionError >>>>>", actionError);
              console.log("updatedPaymentIntent >>>>>", updatedPaymentIntent);
              if (actionError) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  titleText: "Payment failed",
                  text: actionError?.message || 'Invalid card!',
                })
                setIsLoading(false);
              } else if (updatedPaymentIntent.status === 'succeeded') {
                token['stripe_order'] = updatedPaymentIntent;
                successToast("Payment successful for Auto-vignette");
                console.log("PaymentForm invoking callback2 with savePartnersSaleRes",savePartnersSaleRes);
                props.callback(token, "Stripe", savePartnersSaleRes);
                console.log('Payment succeeded');
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  titleText: "Payment failed",
                  // text: actionError?.message || 'Invalid card!',
                })
                setIsLoading(false);
                console.log('Payment failed');
              }
            }
          } 
          else {
            // errorToast("Payment failed for Auto-vignette");
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              titleText: "Payment failed",
              text: configRes?.message || 'Invalid card!',
            })
            saveErrorLogs({
              error: configRes?.message,
              function_name: "stripePayment",
              type: "Stripe Payment",
            })
          }
          // setIsLoading(false);
        }, err => {
          console.log("err >>>", err);
        });
      } 
      else {
        setErrors(tokerror);
        setIsLoading(false);
      }
    }
  };

  const handlePayNow = async (event) => {
    try {
      console.log("event >>>>", event);
      let tokerror = null;
      let token = {};
      let processPaymentAtEnd = false;
      let openPopupNeeded = false;
      let savePartnersSaleRes = null;
      let vigForBulgaria = null;
      if (!(event && event.id)) {
        // for stripe card
        event.preventDefault();
        if (!stripe || !elements) {
          return;
        }
        const stripeToken = await stripe.createToken(elements.getElement(CardNumberElement));
        console.log("stripeToken >>>", stripeToken);
        if (!stripeToken) {
          errorToast("Please enter valid payment details");
          setIsLoading(false);
          return;
        }
        if (stripeToken && stripeToken.error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            titleText: stripeToken.error.code || 'Something went wrong',
            text: stripeToken.error.message || 'Invalid card!',
          })
          setIsLoading(false);
          return;
        }

        token = stripeToken.token;
        console.log(">>>> Stripe-2 token", token)
      }

      if (props.allInfo.allVignettes != null && props.allInfo.allVignettes.length > 0) {
        setIsLoading(true);
        for (let loopIndex in props.allInfo.allVignettes) {
             let vigInfo = props.allInfo.allVignettes[loopIndex];
             console.log(">>> Now processing VigInfo",vigInfo);
             if (vigInfo.processing == 'PaymentOnly') {  // For PaymentOnly eg. Austria just complete the payment and invoke REST to send email
                processPaymentAtEnd = true;
                openPopupNeeded = true;
                continue;
             }
             if (vigInfo.processing !== 'PaymentAndDigitoll') {
                console.log("AAAAA - Unknown processing option or not implemented - "+vigInfo.processing+" for country "+vigInfo.countryName);
                continue;
             }
             processPaymentAtEnd = true;
             vigForBulgaria = vigInfo;
        }  // end of for loop
        console.log("Loop has ended, we are here with flag",processPaymentAtEnd);
        if (processPaymentAtEnd) {
           if (vigForBulgaria != null) {
            let vigInfo = vigForBulgaria;
            console.log("AAAAA - Now processing digitoll for Destination country "+vigInfo.countryName);
             //
             // process and Digitoll Token only for Bulgaria
             // 
             digitollPost({
              "url": "user/authenticate",
              "delvyBody": {}
             }).then((tokenRes) => {
                if (tokenRes.status) {
                  //
                  // Get digitoll products
                  //
                  digitollPost({
                    "token": tokenRes.data.token,
                    "url": "products",
                    "method": "GET",
                  }).then((res1) => {
                    let kapschProduct = {};
                    if (res1.status) {
                      // console.log("Digitoll Products",res1.data);
                      for (let i in res1.data) {
                        //  console.log("Comparing ["+res1.data[i].validityTypeText+"] with ["+vigInfo.durationName+"]");
                        if (res1.data[i].validityTypeText === vigInfo.durationName) {
                          if (res1.data[i].vehicleType === 'car' && vigInfo.vehicleName === 'Car') {
                             kapschProduct = res1.data[i];
                          }
                          if (res1.data[i].vehicleType === 'trailer' && vigInfo.vehicleName !== 'Car') {
                             kapschProduct = res1.data[i];
                          }
                        }
                      }
                    } else {
                      saveErrorLogs({
                        error: res1?.message,
                        function_name: "products",
                        type: "Digitoll products get",
                      })
                    }
                    let startDate = new Date();
                    // if (vigInfo.isBuyNow) {
                    //   startDate.setMinutes(startDate.getMinutes() + 2);
                    // } else {
                      startDate = vigInfo.startDate; // props.allInfo.startDate;
                    // }
                    console.log("Selected Digitoll product",kapschProduct);
                    console.log("UserInfo",props.allInfo.userInfo.email);
                    console.log("ActivationDate startDate",startDate);
                    digitollPost({
                      "token": tokenRes.data.token,
                      "url": "partners/sale",
                      "delvyBody": {
                        "posId": "123",
                        "saleRows": [
                          {
                            "activationDate": new Date(startDate).toISOString(),
                            "email": props.allInfo.userInfo.email,
                            "kapschProductId": kapschProduct.id,
                            "remoteClientId": "",
                            "vehicle": {
                              "countryCode": props.allInfo.registrationCountry.countryCode || "BG",
                              "lpn": props.allInfo.license_plate.license_plate
                            }
                          }
                        ]
                      }
                    }).then((partnersSaleRes) => {
                      console.log("PaymentForm partnersSaleRes",partnersSaleRes);
                      if (partnersSaleRes.status) {
                        savePartnersSaleRes = partnersSaleRes;
                        processPaymentAtEnd = true;
                        console.log("Now invoking completeStripePaymentOnly with",savePartnersSaleRes);
                        completeStripePaymentOnly(event,tokerror,token,savePartnersSaleRes);
                        if (openPopupNeeded) {
                           openPopup();
                        }            
                      } 
                      else {
                        let errorMsg = "";
                        let errorMsg2 = "";
                        saveErrorLogs({
                          error: partnersSaleRes?.message,
                          function_name: "Digitoll Place order",
                          type: "Digitoll (partners/sale)",
                        })
                        if (partnersSaleRes?.message) {
                          errorMsg = partnersSaleRes.message;
                        }
                        if (partnersSaleRes?.message?.message) {
                          errorMsg2 = partnersSaleRes.message.message;
                        }
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          titleText: errorMsg2 || 'Something went wrong',
                          text: errorMsg || 'Digitoll Server Error',
                        })
                        savePartnersSaleRes = null;  // as we encountered error, we don't want to process this vignette 
                        errorToast("Order not placed");
                        setIsLoading(false);
                      }
                    }, err => {
                      console.log(">>>> Falls on this error ???");
                    })
                  }, err => {
                    console.log("1111 >>", err);
                    setIsLoading(false);
                  }, err => {
                    console.log("222 >>", err);
                    setIsLoading(false);
                  })
                } 
                else {
                  console.log("333 >>");
                  setIsLoading(false);
                }
            }, err => {
              console.log("444 >>", err);
              setIsLoading(false);
            })
           }
           else {
               completeStripePaymentOnly(event,tokerror,token,savePartnersSaleRes);
               if (openPopupNeeded) {
                openPopup();
             }
            }
        }
        setIsLoading(false);
        return;
      }  // end of if block to process all the vignettes
      else {
        console.log("Sorry nothing to process");
        return;
      }
    } catch (error) {
      // Handle the error here
      setIsLoading(false);
      console.log(error.message);
      console.log("error >>>>>>>>>>", error.message);
    }
  };

  const handleCardNumberChange = (event) => {
    setCardNumber(event.complete);
  };

  const handleCardExpiryChange = (event) => {
    setCardExpiry(event.complete);
  };

  const handleCardCvcChange = (event) => {
    setCardCvc(event.complete);
  };

  const handlePayPalCheckout = (event) => {
    // setCardCvc(event.complete);
  };

  const saveErrorLogs = (data) => {
    // add
    const Body = {
      "record": {
        site: "Vignette frontend",
        error: JSON.stringify(data.error || {}),
        function_name: data.function_name,
        type: data.type,
        create_user: props.loginUser['_id'],
        status: "ACTV",
        deleted: null,
        deletedby: null,
      },
      "table": "error_logs",
      "uniqueFields": {},
      "uniqueFieldErrMsg": "Error in error_logs save"
    }

    createRecord(Body).then(async (res) => {
      console.log("error_logs >>>>>", res);
      setIsLoading(false)

    }, err => { setIsLoading(false) })
  }
  
  {/*  AA - Feb 20 2024 - removed GiroPay as per Nurettin on Feb 19th meeting (see removed-code.bak) - removed-2 */}

  return (
    <div className="card-number-display">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="form-group mb-3">
              <div className="ls-inputicon-box">
                <CardNumberElement onChange={handleCardNumberChange} options={CARD_ELEMENT_OPTIONS} className='form-control stripe-pay-formcontrol pading_paycard' />
                <i className="fs-input-icon fa-solid feather-credit-card"></i>
              </div>
              {(errors && (errors.code === 'incomplete_number' || errors.code === 'invalid_number')) ? <p className="text-danger mb-0">{errors.message}</p> : ''}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <div className="ls-inputicon-box">
                <CardExpiryElement onChange={handleCardCvcChange} options={CARD_ELEMENT_OPTIONS} className='form-control ps-3 stripe-pay-formcontrol' />
              </div>
              {(errors && (errors.code === 'incomplete_expiry' || errors.code === 'invalid_expiry_year_past')) ? <p className="text-danger mb-0">{errors.message}</p> : ''}
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="form-group">
              <div className="ls-inputicon-box">
                <CardCvcElement onChange={handleCardExpiryChange} options={CARD_ELEMENT_OPTIONS} className='form-control ps-3 stripe-pay-formcontrol' />
              </div>
              {(errors && (errors.code === 'incomplete_cvc')) ? <p className="text-danger mb-0">{errors.message}</p> : ''}
            </div>
          </div>
        </div>
      {!isLoading ?
        <div className="row">
        <div className="col-md-12 text-center">
          {showPopup && <PopupDialog message={t("Payment was successful, an email will be sent to you near the activation date or time. Please wait for your vignette activation email before crossing the border.")} onClose={closePopup} />}
          {(cardNumber && cardExpiry && cardCvc) ?
            <button type="button" className="site-button" onClick={handlePayNow} disabled={!stripe || !elements} ><i className="feather-credit-card"></i> {t('Pay Now')} {exchangeRates.absolute_value} {formatAmount(props.allInfo.amount)}</button> :
            <button type="button" className="site-button btn-disabled" disabled ><i className="feather-credit-card"></i> {t('Pay Now')} {exchangeRates.absolute_value} {formatAmount(props.allInfo.amount)}</button>
          }
          {/* AA - Feb 20 2024 - removed GiroPay as per Nurettin on Feb 19th meeting
          {props.allInfo.giropay_payment_show &&
            <div className='mt-3'>
              <img className='pointer' onClick={handlePayment} src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Giropay.svg/2560px-Giropay.svg.png" width="135px" alt="" />
            </div>
          }
        */}
          {/* <button type="button" className="site-button " onClick={() => handlePayPalCheckout()}>
        </button> */}
        </div></div> :
        <div className="row">
        <div className="col-md-12 text-center">
          <button type="button" className="site-button btn-disabled" disabled ><i className="feather-credit-card"></i> <img src="assets/images/loader.svg" width={25} /></button>
          <p style={{ color: 'blue' }}>Processing...</p>
        </div>
        </div>
      }
      </div>
    </div>
  );
};

export default PaymentForm;