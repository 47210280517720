
import { HOME_DATA, SWIPER_DATA } from "./Type";
import axios from "axios";
import React from "react";

// const baseURL = "http://localhost:3011/curd"; // Local BE
// const baseURL = "https://api.auto-vignette.com/curd";  // Live Server
const baseURL = "https://testapi.auto-vignette.com/curd";  // Test SSL Server

// Get single table data
export const readCDValuesByCode = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/readCDValuesByCode", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Get single table data
export const readRecord = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/readRecord", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Get multiple table data
/*
export const readRecordJoin = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/readRecordJoin", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}
*/
// Add Data
export const verifyEmailCode = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/verifyemailcode", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

export const createRecord = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/createRecord", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Update Data
export const updateRecord = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/updateRecord", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Delete Data
export const deleteRecord = (data) => {
    // "deleterecord": "0" = deleted null
    // "deleterecord": "1" = permanent delete
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/deleteRecord", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Make stripe payment
export const stripePayment = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/stripe", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

export const paypalPayment = (data) => {
    console.log("paypalPayment >>>>>>>>>>>>>>>>>>>>>>>>>>", data);
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(baseURL + "/paypal", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });;
            const json = await response.json();
            resolve(json)
        } catch (error) {
            console.log("error json >>>>>>>", error);
            reject(error)
        }
    })
}

export const applepayPayment = (data) => {
    console.log("applepayPayment >>>>>>>>>>>>>>>>>>>>>>>>>>", data);
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(baseURL + "/applepay", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });;
            const json = await response.json();
            resolve(json)
        } catch (error) {
            console.log("error json >>>>>>>", error);
            reject(error)
        }
    })
}

// Email send
export const sendEmail = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/sendEmail", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Email send
export const createPdf = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL + "/createpdf", data).then((resp) => {
            resolve(resp.data);
        }).catch(error => {
            console.log(error);
            reject(error);
        })
    })
}

// Digitoll
export const digitollPost = (data) => {
    return new Promise(async (resolve, reject) => {
          const response = await fetch(baseURL + "/digitoll", {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'token': data.token || "",
            },
            body: JSON.stringify(data),
        });
        const json = await response.json();
        resolve(json);
    })
}

export const homedata = (finddata) => ({

    type: HOME_DATA,
    payload: finddata || []

})

export const formatAmount = (amount) => {
    if(!amount){
        amount = 0;
    }
    return amount.toFixed(2);
}


